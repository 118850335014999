import React from 'react'
import Advantage01 from './images/advantage01'
import Advantage02 from './images/advantage02'
import Advantage03 from './images/advantage03'

export default function Philosophy({ id, title, contents }) {
  function PhilosophyImage({ id }) {
    if (id === 'philosophy-01') {
      return <Advantage01 />
    }
    if (id === 'philosophy-02') {
      return <Advantage02 />
    }
    if (id === 'philosophy-03') {
      return <Advantage03 />
    }
  }

  return (
    <div className="mt-20">
      <PhilosophyImage id={id} />
      <h3 className="text-xl md:text-2xl font-medium mt-10">{title}</h3>
      <p
        dangerouslySetInnerHTML={{ __html: contents }}
        className="mt-4 leading-7"
      />
    </div>
  )
}
