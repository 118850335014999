import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Heading from '../components/heading'
import Philosophy from '../components/philosophy'
import SEO from '../components/seo'

export default function Advantage() {
  const title = {
    jp: 'こだわり',
    en: 'ADVANTAGE'
  }

  const heading = '企業理念'

  const philosophy = 'おいしさのため、安心のため、こだわり続ける3つのこと'

  const items = [
    {
      id: 'philosophy-01',
      title: '1.おいしいものだけを届ける',
      contents:
        '同じ浜で採れた「わかめ」といっても、毎年同じ味だとは限らないことを知っていますか。その年の気候や海水温、波の荒さ、海水の水質や栄養によって、そこに育つ「わかめ」の味は変わるのです。<br />' +
        'だから、私たちは、仕入れる浜を決めずに、毎年いろいろな浜の「わかめ」を実際に試食し、その年の「一番おいしいわかめ」を仕入れる方法をとっています。<br />' +
        '「もずく」をおいしく食べてもらうためには、添付するタレも何度も試作し吟味を重ね、特注のタレを製作。乾燥で仕入れる「ひじき」は、最もおいしく戻す水の分量を研究し、取引先から「サンリクのひじきは他とは違う」と言われています。<br />' +
        '「どんなに手間がかかろうと、おいしいものだけを届けたい」。<br />' +
        'これがサンリクの思いです。'
    },
    {
      id: 'philosophy-02',
      title: '2.日本国内産の海藻にこだわる',
      contents:
        '日本の海で育った海藻は、味も香りも歯ごたえも、外国産のものとは比べようがないほど、海藻本来の深い味、高い香り、肉厚な歯ごたえを持っています。<br />' +
        'これらは、人の力ではどうにもならないことで、日本の海の持つ力が、海藻を育てていると言えるでしょう。<br />' +
        '冷たい荒波にもまれた「岩手産わかめ」の香りとしゃきしゃきとした歯ごたえ、うず潮の早い流れに育てられた、しなやかで美しい緑色を持つ「徳島産わかめ」。<br />' +
        'やはり自分たちが食べてみて、これはおいしいと思えたものは、日本の海で育った国内産の「わかめ」なのです。<br />' +
        'また、実際に漁をしている漁師を知り、浜を知っているという「安心感」も国内産にこだわる理由のひとつです。'
    },
    {
      id: 'philosophy-03',
      title: '3.食の安全のためスタッフ全員が一緒になって',
      contents:
        '「わかめ」や「もずく」といった海藻の試食をする時も、新しい商品を考える時にも、仕入れ担当、工場のライン担当、パッキング担当など工場内の商品に関わる全てのスタッフが試食をして、送り出す商品を決めています。<br />' +
        '社長は日々作業着着用で工場に立ち、仕入れた「わかめ」などの海藻ひとつひとつを直接自分の目で見えて、手で触り、厳しく品質チェックをしています。<br />' +
        'また、取引をしている浜には定期的に足を運び、漁師やその浜の漁協の人たちと話をし情報を共有。現地で採れたての「わかめ」を見て、品質を確認することも行っています。<br />' +
        '「おいしさ」にこだわることはもちろん、漁に関わる人を知り、品質も厳しくチェックしていく姿勢は、変わることはありません。自分たちが納得できる商品だけを食卓に届けることも、私たちの責任であると考えています。'
    },
  ]

  return (
    <Layout>
      <SEO title="こだわり | 株式会社サンリク" />
      <PageTitle titleJP={title.jp} titleEN={title.en} />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        <Heading heading={heading} />

        <h2
          className="text-2xl md:text-4xl md:text-center font-bold mt-10 md:mt-20"
          dangerouslySetInnerHTML={{ __html: philosophy }}
        />

        <div className="max-w-screen-lg mt-10 md:mt-20 mx-auto">
          {items.map(item => (
            <Philosophy key={item.id} id={item.id} title={item.title} contents={item.contents} />
          ))}
        </div>
      </div>
    </Layout>
  )
}
