import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function NewsTitle() {
  return (
    <StaticImage
      style={{ gridArea: '1/1' }}
      layout="fullWidth"
      aspectRatio={1425 / 313}
      alt=""
      src="../../images/title-news.jpg"
      formats={[ 'auto', 'webp', 'avif' ]}
    />
  )
}
