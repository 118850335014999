import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function ContactTitle() {
  return (
    <StaticImage
      style={{ gridArea: '1/1', }}
      layout="fullWidth"
      aspectRatio={1425 / 313}
      alt=""
      src="../../images/title-contact.jpg"
      formats={[ 'auto', 'webp', 'avif' ]}
    />
  )
}
