import React from 'react'
import NewsTitle from './images/news-title'
import MessageTitle from './images/message-title'
import AdvantageTitle from './images/advantage-title'
import CompanyProfileTitle from './images/company-profile-title'
import AccessTitle from './images/access-title'
import ProductTitle from './images/product-title'
import ContactTitle from './images/contact-title'
import PrivacyTitle from './images/privacy-title'

export default function PageTitle({ titleJP, titleEN }) {
  function TitleImage({title}) {
    if (title === 'NEWS') {
      return <NewsTitle />
    }
    if (title === 'MESSAGE') {
      return <MessageTitle />
    }
    if (title === 'ADVANTAGE') {
      return <AdvantageTitle />
    }
    if (title === 'COMPANY PROFILE') {
      return <CompanyProfileTitle />
    }
    if (title === 'ACCESS') {
      return <AccessTitle />
    }
    if (title === 'PRODUCT') {
      return <ProductTitle />
    }
    if (title === 'CONTACT') {
      return <ContactTitle />
    }
    if (title === 'PRIVACY POLICY') {
      return <PrivacyTitle />
    }
  }
  return (
    <section>
      <div className="grid h-40vw md:h-auto">
        <TitleImage title={titleEN} />
        <div style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: '1/1',
          position: 'relative',
          // This centers the other elements inside the hero component
          placeItems: 'center',
          display: 'grid',
        }}>
          <div className="absolute bottom-10 left-32">
            <h2 className="text-white m-0 font-bold text-lg md:text-xl">{titleJP}</h2>
            <h2 className="text-white mt-2 font-medium text-2xl md:text-5xl font-crimson tracking-wider">{titleEN}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}
